import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import * as React from 'react';

type RecipeData = {
    title: string;
    subtitle: string;

    type: {
        title: string;
    };

    slug: string;
    url: string;

    cookingTime?: number;
    waitingTime?: number;

    photo: ImageDataLike;

    source:
        | {
              title: string;
              logo: ImageDataLike;
          }
        | undefined;
};

type Properties = {
    data: RecipeData;
    directLink?: boolean;

    className?: string;
};

const recipeDetail = (icon: IconProp, value: string, label: string): React.ReactElement => {
    return (
        <div className={'flex items-center gap-1 px-2 py-0.5 bg-caribbean text-white rounded text-xs'}>
            <FontAwesomeIcon icon={icon} size={'sm'} />{' '}
            <span>
                {value} {label}
            </span>
        </div>
    );
};

const Recipe = ({ data, directLink, className }: Properties): React.ReactElement => {
    const renderDirectLink = undefined === directLink ? Recipe.defaultProps.directLink : directLink;

    let recipeUrl = null;

    recipeUrl = renderDirectLink ? data.url : `/recepten/${data.slug}`;

    return (
        <a
            href={recipeUrl}
            target={renderDirectLink ? '_blank' : '_self'}
            className={classNames(
                'shadow-md rounded-lg transform transition hover:-translate-y-0.5 focus:outline-none focus:ring-4 focus:ring-offset-2 focus:ring-lemon focus:ring-opacity-50',
                className
            )}>
            <GatsbyImage image={getImage(data.photo) as IGatsbyImageData} alt={''} className={'w-full rounded-t-lg'} />
            <div className={'relative p-6 pt-8'}>
                {data.source && (
                    <div
                        className={
                            'absolute -top-16 right-6 p-1 transform overflow-hidden rounded-full bg-white shadow-md hover:animate-wiggle-once'
                        }>
                        <GatsbyImage
                            image={getImage(data.source.logo) as IGatsbyImageData}
                            alt={data.source.title}
                            title={`Recept van ${data.source.title}`}
                            className={'w-20 transform rounded-full'}
                        />
                    </div>
                )}

                <span className={'font-bold text-lg leading-3'}>{data.title}</span>
                {data.subtitle && <div className={'block mt-1 text-gray-400 text-xs lowercase'}>{data.subtitle}</div>}
                <div className={'flex flex-wrap gap-2 mt-2'}>
                    <span className={'px-2 py-0.5 bg-lemon rounded text-xs lowercase'}>{data.type.title}</span>
                    {data.cookingTime && recipeDetail(['far', 'hat-chef'], `${data.cookingTime} min.`, 'koken')}
                    {data.waitingTime && recipeDetail(['far', 'hourglass'], `${data.waitingTime} min.`, 'wachten')}
                </div>
            </div>
        </a>
    );
};

Recipe.propTypes = {
    data: PropTypes.object.isRequired,
    className: PropTypes.string,
};

Recipe.defaultProps = {
    directLink: false,
    className: '',
};

export const query = graphql`
    fragment RecipeData on ContentfulRecipe {
        title
        subtitle
        type {
            title
        }
        slug
        url
        cookingTime
        waitingTime
        photo {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1200, height: 900)
        }
        source {
            title
            logo {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 500, height: 500)
            }
        }
    }
`;

export { Recipe, RecipeData };
