import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Recipe, RecipeData } from '../components/collections/recipe';
import { Hero, ImageClipping } from '../components/containers/hero';
import { Section, Style as SectionStyle } from '../components/containers/section';
import { SectionText } from '../components/containers/section-text';
import { Type as ButtonType } from '../components/elements/button';
import { TextButton } from '../components/elements/text-button';
import { Site } from '../components/shells/site';
import { Seo } from '../components/utilities/seo';

const URI = require('urijs');

type Properties = {
    data: any;
};

const Page = ({ data }: Properties): React.ReactElement => {
    return (
        <Site>
            <Seo title={`Recepten voor ${data.contentfulLanding.title}`} />

            <Hero
                image={<GatsbyImage image={getImage(data.contentfulLanding.hero)} alt={''} />}
                imageClipping={ImageClipping.VerticalBlob}
                pageTitle={`Recepten voor ${data.contentfulLanding.title}`}
                pageText={data.contentfulLanding.shortDescription.shortDescription}
            />

            <Section style={SectionStyle.Light}>
                <SectionText>
                    <GatsbyImage image={getImage(data.contentfulLanding.logo)} alt={''} className={'mx-auto w-80'} />
                    <div
                        className={'mt-16'}
                        dangerouslySetInnerHTML={{
                            __html: data.contentfulLanding.description.childMarkdownRemark.html,
                        }}
                    />
                    {data.contentfulLanding.website && (
                        <TextButton
                            type={ButtonType.Link}
                            url={URI(data.contentfulLanding.website)
                                .query({
                                    utm_campaign: 'partnerships',
                                    utm_source: 'fitfoodforty',
                                    utm_medium: 'landing_page',
                                    utm_content: 'website_button',
                                })
                                .href()}
                            forceBlank={true}
                            caption={`Meer over ${data.contentfulLanding.title}`}
                            className={'mt-8'}
                        />
                    )}
                </SectionText>
            </Section>

            <Section>
                <div className={'grid sm:grid-cols-2 lg:grid-cols-3 gap-6'}>
                    {data.allContentfulRecipe.edges.map((result: any) => (
                        <Recipe data={result.node as RecipeData} directLink={true} />
                    ))}
                </div>
            </Section>
        </Site>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
    query ($slug: String!) {
        contentfulLanding(slug: { eq: $slug }) {
            title
            website

            logo {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, width: 500)
            }
            description {
                childMarkdownRemark {
                    html
                }
            }
            shortDescription {
                shortDescription
            }
            hero {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280, height: 2160)
            }
        }
        allContentfulRecipe(
            filter: { landingPages: { elemMatch: { slug: { eq: $slug } } } }
            sort: { fields: [type___order, title], order: ASC }
        ) {
            edges {
                node {
                    ...RecipeData
                }
            }
        }
    }
`;

export default Page;
